import React from "react";
import { elevaTheme, ThemeProvider, I18nContextProvider } from "@thinktuminc/react-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { DtcConfigContext } from "./components/dao/Config";
import { LoginContextProvider } from "@thinktuminc/core-ui-shared-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LanguageSelection from "./components/LanguageSelection/LanguageSelection";
import Login from "./components/Login/Login";
import GetStarted from "./components/GetStarted/GetStarted";
import TestPadWrapper from "./components/TestPad/TestPadWrapper";
import { Layout } from "./components/Layout";
import PrivateRoute from "./components/utils/PrivateRoute";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

function MyApp(): JSX.Element {

    const client = new QueryClient( {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                notifyOnChangeProps: ["data", "error"]
            },
        },
    } );

    const makePrivate = ( children:JSX.Element ) => <PrivateRoute>{children}</PrivateRoute>;

    return (
        <QueryClientProvider client={client}>
            <I18nContextProvider>
                <LoginContextProvider>
                    <DtcConfigContext.Provider>
                        <ThemeProvider theme={elevaTheme}>
                            <Router>
                                <Routes>
                                    <Route path="/" element={<Layout />}>
                                        <Route index element={<Login />} />
                                        <Route path="language-selection" element={makePrivate( <LanguageSelection /> )} />
                                        <Route path=":language" element={makePrivate( <GetStarted /> )} />
                                        <Route path="flow">
                                            <Route path=":id" element={makePrivate( <TestPadWrapper /> )} />
                                        </Route>
                                    </Route>
                                </Routes>
                            </Router>
                        </ThemeProvider>
                    </DtcConfigContext.Provider>
                </LoginContextProvider>
            </I18nContextProvider>
        </QueryClientProvider>
    );

}

export default MyApp;
