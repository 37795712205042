import { LanguageCodes, LanguageConfig } from "@thinktuminc/application-models";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { APIUtils } from "@thinktuminc/core-ui-shared-react";
import { ModuleConfig } from "../Config";

export class LanguageDataAccessAPI {

    private readonly URL: string;
    private logger = new BrowserLogger( "LanguageDataAccess.api" );

    constructor( private readonly apiUtils: APIUtils, private readonly config: ModuleConfig ) {
        this.URL = `${config?.applicationServiceUrl}`;
    }

    async getApplicationLanguages(): Promise<LanguageConfig> {
        if ( this.URL ) {
            this.logger.info( "Getting all languages available" );
            return this.apiUtils.get( this.URL + "/configuration/language", {} );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( {} as LanguageConfig );
        }
    }

    async getLanguageFlowId( languageCode: LanguageCodes ): Promise<string> {
        if ( this.URL ) {
            this.logger.info( `Getting flow id for language code ${languageCode}` );
            return this.apiUtils.get( this.URL + `/configuration/flow?languageCode=${languageCode}`, {} );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( {} as string );
        }
    }

}