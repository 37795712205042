import { BrowserLogger } from "@thinktuminc/browser-logging";
import { APIUtils } from "@thinktuminc/core-ui-shared-react";
import { ModuleConfig } from "../Config";

export class AuthDataAccessAPI {

    private readonly URL: string;
    private logger = new BrowserLogger( "AuthDataAccess.api" );
    constructor( private readonly apiUtils: APIUtils, private readonly config: ModuleConfig ) {
        this.URL = `${config?.authServiceUrl}`;
    }
    
    async loginApplication( ):Promise<string>{
        if ( this.URL ) {
            this.logger.info( "Login into Application Flow" );
            return this.apiUtils.get( this.URL + "/dtc", {} );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( "" );
        }
    }
}