import { ApplicationMetaData, LanguageCodes } from "@thinktuminc/application-models";
import { useContext, useMemo } from "react";
import { ApplicationDataAccessAPI } from "../../components/dao/Applications/ApplicationDataAccess.api";
import { useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { DtcConfigContext, ModuleConfig } from "../../components/dao/Config";
import { LanguageDataAccessAPI } from "../../components/dao/Languages/LanguageDataAccess.api";
import { useNavigate } from "react-router-dom";
import { Grid, TextWithButtonLayout, I18nContext } from "@thinktuminc/react-components";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { Id } from "@thinktuminc/common-data-types";
import { FlowDataAccessAPI } from "../dao/Flows/FlowDataAccess.api";
import { I18nText } from "@thinktuminc/i18n";

const header = new I18nText( {"en":"Eleva Finance has partnered with thinktum to provide a safe and easy customer journey.", "fr":"Eleva Finance s'est associée à thinktum pour offrir un parcours client sûr et facile."} );
const bodyMessages = new I18nText( {"en":"Completing this process will allow Eleva Finance to recommend the very best financial solutions to suit your needs.", "fr":"La réalisation de ce processus permettra à Eleva Finance de vous recommander les meilleures solutions financières adaptées à vos besoins."} );
const buttonText = new I18nText( {"en":"Get started", "fr":"Commencer"} );

const GetStarted = () : JSX.Element => {
    const navigate = useNavigate();
    const logger = new BrowserLogger( "GetStarted" );
    const i18nContext = useContext( I18nContext );
    const language = i18nContext.language ?? "en";
    const config = useContext( DtcConfigContext.ReactContext );
    const apiUtils = useAPIUtils();
    const applicationDataAccess = useMemo( () => new ApplicationDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );
    const languageDataAccess = useMemo( () => new LanguageDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );
    const flowDataAccess = useMemo( () => new FlowDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );
    const handleClick = async () => {
        try {
            const flowId = await languageDataAccess.getLanguageFlowId( language as LanguageCodes ?? "en" );
            const flow = await flowDataAccess.getApplicationFlowDetials( flowId );
            if ( flow === null ) {
                throw new Error( "No application found" );
            }
            const meta:ApplicationMetaData = {
                flowId: flowId as Id,
                form: "Some Form OOD",
                flow: flow.title,
                header: {
                    name: "",
                    household: "",
                    email: "",
                    flow: "",
                    product: "SomeProduct OOD",
                    faceAmount: 0,
                    status: "pending",
                    lastUpdated: new Date(),
                    _security: {
                        paths: []
                    }
                },
                product: "SomeProduct OOD",
                productId: "1-1-1-1-1-1OOD"
            };
            const id = await applicationDataAccess.createApplication( {_meta: meta} );
            navigate( "/flow/" + id, { state:  {flowId: flowId }} );
        } catch ( e:any ){
            logger.error( e );
        }
    };

    return (
        <div style={{ width: "100%", height: "100%"}}>
            <Grid container className='grid-container' style={{height: "100%"}}>
                <Grid item xs={12} >
                    <TextWithButtonLayout
                        header={header.getText( language )}
                        bodyMessages={<p>{bodyMessages.getText( language )}</p>}
                        buttonText={buttonText.getText( language )}
                        execute={() => handleClick()}
                    />
                </Grid>
            </Grid>
        </div>
    );

};

export default GetStarted;
