import { useContext, useCallback } from "react";
import { Grid, DTCLayout, I18nContext, Box, useTheme, useMediaQuery } from "@thinktuminc/react-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../../styles/globals.css";
import FooterEn from "./footer-liz-english.png";
import FooterFr from "./footer-liz-french.png";
import { alpha } from "@material-ui/core/styles";

const transparentImage = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiID8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+Cjwvc3ZnPg==";

const Layout = (): JSX.Element => {

    const {translate} = useContext( I18nContext );
    const theme = useTheme();
    const isMobile = useMediaQuery( theme.breakpoints.down( "xs" ) );
    const footerBackgroundColor = ( isMobile ? theme.palette.background.paper : theme.palette.background.default );
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = useCallback( () => {    
        if ( location.pathname == "/language-selection" ){
            window.location.href = "https://elevafinance.com/";
        } else {
            navigate( "/" );
        }
    }, [location.pathname] );

    return (
        <>
            <div className="layout-container" style={{overflow: "auto", height:"calc(100% - 30px)"}}>
                <Grid xs={12} sm={11} md={10} lg={9} xl={8} container direction={"column"} style={{ height: "100%", width: "100%", display: "flex"}}  >
                    <DTCLayout
                        logoURL={translate( {en:"/logo-eleva-english.png", fr:"/logo-eleva-french.png"} )}
                        onClose={handleClick}
                        body={<Grid style={{width: "100%"}}><Outlet /></Grid>}
                        footerURL={transparentImage}
                    />
                </Grid>

            </div>
            <Box zIndex={0} position="absolute" bottom={0} padding={2} style={{
                background: `linear-gradient(0deg, ${footerBackgroundColor} 0%, ${footerBackgroundColor} 80%, ${alpha( footerBackgroundColor, 0 )} 100%)`
            }} width="100%" textAlign="center">
                <img src={translate( {en:FooterEn, fr: FooterFr} )} style={{maxHeight:"22px"}} />
            </Box>
        </>
    );
};

export { Layout };