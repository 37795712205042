import { LoginContext } from "@thinktuminc/core-ui-shared-react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

export default function PrivateRoute( {children}: {children: JSX.Element} ):JSX.Element {
    const session = useContext( LoginContext );
    if ( session.login.state !== "LOGGED_IN" ) {
        return <Navigate to="/" />;
    }
    return children;
}