import React, { useContext } from "react";
import { LanguageCodes, LanguageConfig } from "@thinktuminc/application-models";
import { useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { Button, I18n, Grid, DataLoader, Box, I18nContext } from "@thinktuminc/react-components";
import "../../styles/globals.css";
import { DtcConfigContext, ModuleConfig } from "../../components/dao/Config";
import { LanguageDataAccessAPI } from "../../components/dao/Languages/LanguageDataAccess.api";
import { useNavigate } from "react-router-dom";
import { makeStyles} from "@thinktuminc/react-components";

const useStyles = makeStyles( ( theme ) => ( {
    button: {
        "& p": {
            textTransform: "none",
            fontWeight: 600,
            lineHeight: "22px",
        },
        [theme.breakpoints.down( "xs" )]: {
            height: "60px",
            width: "100%",
            border: "1px solid #B2B2B2",
            "& p": {
                fontSize: "18px",
                fontWeight: 400
            }
        },
        [theme.breakpoints.up( "sm" )]: {
            height: "48px",
            width: "fit-content",
            paddingLeft: "20px",
            paddingRight: "20px",
            position: "relative",
            marginTop: "32px",
            "& p": {
                color: "#FAFAFB",
                fontSize: "24px",
            }
        }
    },
    header: {
        color: "#323E48",
        fontWeight: 600,
        [theme.breakpoints.down( "xs" )]: {
            fontSize: "23px",
            lineHeight: "28px",
            marginBottom: "14px"
        },
        [theme.breakpoints.up( "sm" )]: {
            fontSize: "32px",
            lineHeight: "40px",
            marginBottom: "14px"
        }
    },
    bodyWrapper: {
        position: "relative"
    },
    bodyMessages: {
        color: "#323E48",
        fontWeight: 400,
        [theme.breakpoints.down( "xs" )]: {
            fontSize: "18px",
            lineHeight: "21.6px"
        },
        [theme.breakpoints.up( "sm" )]: {
            fontSize: "24px",
            lineHeight: "28px"
        }
    }
} ) );
  
const LanguageSelection = (): JSX.Element => {
  
    const apiUtils = useAPIUtils();

    const config = useContext( DtcConfigContext.ReactContext );
    const languageDataAccess = React.useMemo( () => new LanguageDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );
    
    const { setI18nContext } = React.useContext( I18nContext );

    const navigate = useNavigate();
    const loadData = React.useCallback( async (): Promise<LanguageConfig> => {
        if ( config ) {
            return languageDataAccess.getApplicationLanguages();
        } else {
            return Promise.resolve( {} ) as Promise<LanguageConfig>;
        }
    }, [config, languageDataAccess] );

    const handleClick = ( lang: string ) => {
        setI18nContext( {
            language: lang,
            setI18nContext
        } );
        navigate( `/${lang}` );
    };

    const ResponsiveButton = ( props: React.FC, variant: "outlined" | "contained", lang: {
        languageCode: LanguageCodes;
        language: string;
    } )=> <Button 
        size="large" 
        variant={variant}
        className="lang-button"
        aria-label={lang.language} 
        onClick={() => handleClick( lang.languageCode )}
        {...props} 
    ><I18n text={lang.language} /></Button>;
    const classes = useStyles();

    return (
        <div style={{ width: "100%"}}>
            <Grid container className='grid-container'>
                <Grid item xs={12}>
                    <I18n className={classes.header} variant='h1' text={"Welcome | Bienvenue"} />
                </Grid>
                <Grid item xs={12}>
                    <I18n className={classes.bodyMessages} variant='subtitle1' text={"Please select your language preference / Veuillez choisir une langue d’usage"} />
                </Grid>
                <Grid item xs={12}>
                    <DataLoader<LanguageConfig>
                        loadingMessage={{ en: "Loading languages" }}
                        load={loadData}
                        render={( config => {
                            return ( <>
                                <Grid container className="lang-buttons-container" >
                                    { 
                                        config?.data?.map( ( lang, index ) => {
                                            return (
                                                <>
                                                    <Box className={classes.button} key={index} component={( props )=>ResponsiveButton( props, "contained", lang )} sx={{ display: { xs: "none", sm: "block" }, width: "auto", marginX: "8px" }} style={{marginTop: 16}}/>
                                                    <Box className={classes.button} key={index} component={( props )=>ResponsiveButton( props, "outlined", lang )} sx={{ display: { xs: "block", sm: "none" }, width: "100%", marginX: 0}} style={{marginTop: 16}} />
                                                </>
                                            ); 
                                        } )
                                    }
                                </Grid>
                            </> );
                        } )} />
                </Grid>
            </Grid>
        </div>
    );

};

export default LanguageSelection;
