import { BrowserLogger } from "@thinktuminc/browser-logging";
import { ConfigurationContext, fromPublicJson } from "@thinktuminc/core-ui-shared-react";

const logger = new BrowserLogger( "ModuleConfig" );
export class ModuleConfig {
    constructor( values: Partial<ModuleConfig> ){
        logger.info( "Creating module config" );
        Object.assign( this, values );
    }
    public applicationServiceUrl!: string;
    public pipelineServiceUrl!: string;
    public authServiceUrl!: string;
    public authorizeUrl!: string;
}

export const DtcConfigContext = new ConfigurationContext( ModuleConfig, {
    "applicationServiceUrl": {
        retriever: fromPublicJson( "/config.json", "applicationServiceUrl" ),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:18080"
    },
    "pipelineServiceUrl": {
        retriever: fromPublicJson( "/config.json", "pipelineServiceUrl" ),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:10100"
    },
    "authServiceUrl": {
        retriever: fromPublicJson( "/config.json", "authServiceUrl" ),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:3002"
    },
    "authorizeUrl": {
        retriever: fromPublicJson( "/config.json", "authorizeUrl" ),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:3002/auth/"
    },
} );
