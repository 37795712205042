import { BrowserLogger } from "@thinktuminc/browser-logging";
import { APIUtils } from "@thinktuminc/core-ui-shared-react";
import { ModuleConfig } from "../Config";
import { FlowDetails, FlowType, NodeDisplay } from "@thinktuminc/pipeline-library";
import { Id } from "@thinktuminc/common-data-types";
export class FlowDataAccessAPI {

    private readonly URL: string;
    private logger = new BrowserLogger( "FlowDataAccess.api" );
    constructor( private readonly apiUtils: APIUtils, private readonly config: ModuleConfig ) {
        this.URL = `${config?.pipelineServiceUrl}`;
    }

    async getApplicationFlow( flowId: Id, type: FlowType, subtype: string ): Promise<NodeDisplay[]> {
        if ( this.URL ) {
            this.logger.info( "Getting flow by id: ", flowId );
            return this.apiUtils.get( this.URL + `/flow/${flowId}?type=${type}&subtype=${subtype}`, {} );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( [] );
        }
    }

    async getApplicationFlowDetials( flowId: string ): Promise<FlowDetails> {
        if ( this.URL ) {
            this.logger.info( "Getting flow details by id: ", flowId );
            return this.apiUtils.get( this.URL + `/flow/${flowId}/details`, {} );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( {} as FlowDetails );
        }
    }
}