import { Box, DataLoader, useMediaQuery, useTheme } from "@thinktuminc/react-components";
import { useCallback, useContext, useMemo, useRef } from "react";
import { FlowType, NodeDisplay } from "@thinktuminc/pipeline-library";
import { Id } from "@thinktuminc/common-data-types";
import { FlowDataAccessAPI } from "../../components/dao/Flows/FlowDataAccess.api";
import { useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { DtcConfigContext, ModuleConfig } from "../../components/dao/Config";
import { useLocation, useParams } from "react-router-dom";
import { Application, ApplicationStatus, NodeTypes } from "@thinktuminc/application-models";
import { ApplicationProcessController, ApplicationProcessTryMeHighLevel, ApplicationSaveContextProvider, EventsTrackingContextProvider } from "@thinktuminc/application-components";
import { ApplicationDataAccessAPI } from "../dao/Applications/ApplicationDataAccess.api";

const TestPadWrapper = () : JSX.Element => {
    const { state } = useLocation();
    const {id} = useParams<{id:Application["id"]}>();
    const theme = useTheme();
    const isMobile = useMediaQuery( theme.breakpoints.down( "xs" ) );
    const flowId = state.flowId;
    const config = useContext( DtcConfigContext.ReactContext );
    const apiUtils = useAPIUtils();
    const flowDataAccess = useMemo( () => new FlowDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );
    const processController = useRef( new ApplicationProcessController( {nodeTypes: NodeTypes as any} ) );
    const applicationDataAccess = new ApplicationDataAccessAPI( apiUtils, config as ModuleConfig );
    
    const load = useCallback( async ( subtype: string, incomingId?: Id, incomingType?: FlowType ): Promise<NodeDisplay[]>  => {
        if ( config ) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return await flowDataAccess.getApplicationFlow( incomingId!, incomingType!, subtype  );
        } else {
            return Promise.resolve( {} ) as Promise<NodeDisplay[]>;
        }
    }, [config, flowDataAccess] );

    const changeApplicationStatus = useCallback( async ( newStatus:ApplicationStatus ) => {
        if ( !id ) {
            return;
        }
        await applicationDataAccess.changeStatus( id, newStatus );
    }, [applicationDataAccess] );

    return <ApplicationSaveContextProvider appDataAccessAPI={applicationDataAccess} >
        <EventsTrackingContextProvider config={undefined}>
            <DataLoader<( NodeDisplay[] )>
                loadingMessage={{en:"Loading application flow", fr:"Chargement application flow"}}
                load={() => load( "default", flowId, "application" )}
                render={ data => {
                    if ( data ){
                        return <ApplicationProcessTryMeHighLevel 
                            flowId={flowId as Id}
                            flow={data}
                            processController={processController.current}
                            load={load}
                            testPadCompOptions={{
                                save: true,
                                inputs: true,
                                diagnosis: false
                            }}
                            changeApplicationStatus={changeApplicationStatus}
                        />;
                    } else {
                        return <></>;
                    }
                          
                } }
            />
            {isMobile && <Box height={"1em"} padding={"1em"} margin={"1em"} />}
        </EventsTrackingContextProvider>
    </ApplicationSaveContextProvider>;
};

export default TestPadWrapper;