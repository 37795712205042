import React, { useCallback, useContext, useMemo } from "react";
import { useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { DtcConfigContext, ModuleConfig } from "../dao/Config";
import { LoginContext } from "@thinktuminc/core-ui-shared-react";
import { AuthDataAccessAPI } from "../dao/Auth/AuthDataAccess.api";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { JWTDecode, JWTDecodeConfiguration } from "@thinktuminc/authentication";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid } from "@thinktuminc/react-components";

const logger = new BrowserLogger( "LoginPage" );

const Login = (): JSX.Element => {

    const loginContext = useContext( LoginContext );
    const config = useContext( DtcConfigContext.ReactContext );
    const jwtDecoder = useCallback( () => new JWTDecode( new JWTDecodeConfiguration( { publicKey: "" } ) ), [] )();
    const apiUtils = useAPIUtils();
    const authDataAccess = useMemo( () => new AuthDataAccessAPI( apiUtils, config as ModuleConfig ), [config, apiUtils] );

    const navigate = useNavigate();

    useMemo( async (): Promise<void> => {
        if ( config ) {
            authDataAccess.loginApplication().then( token => {
                logger.info( "Successfully logged in", loginContext );
                loginContext.setLogin( {
                    ...loginContext,
                    login: {
                        state: "LOGGED_IN",
                        JWTToken: token,
                        Details: jwtDecoder.decodeSession( token )
                    },
                } );
                navigate( "language-selection" );
            } )
                .catch( err => { throw err; } );
        }
    }, [config, authDataAccess, jwtDecoder, loginContext, navigate] );

    return ( <Grid container justifyContent="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
        <CircularProgress  />
    </Grid> );
};

export default Login;

