import { BrowserLogger } from "@thinktuminc/browser-logging";
import { APIUtils } from "@thinktuminc/core-ui-shared-react";
import { ModuleConfig } from "../Config";
import { ApplicationSaveDetails, ApplicationStatus, NewApplication } from "@thinktuminc/application-models";
import { ApplicationAnyEvent } from "@thinktuminc/common-data-types";
import { Guard } from "@thinktuminc/exception-handling";

export class ApplicationDataAccessAPI {

    private readonly URL: string;
    private logger = new BrowserLogger( "ApplicationDataAccess.api" );
    constructor( private readonly apiUtils: APIUtils, private readonly config: ModuleConfig ) {
        this.URL = `${config?.applicationServiceUrl}`;
    }
    
    async createApplication( data:NewApplication ):Promise<string>{
        if ( this.URL ) {
            this.logger.info( "Creating new application" );
            return this.apiUtils.post( this.URL + "/application", {}, data );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( "" );
        }
    }

    async update( id: string, data: { events: ApplicationAnyEvent[], data: ApplicationSaveDetails & {flags: string[], flowId: string} } ): Promise<string> {
        if ( !( typeof ( id ) === "string" ) ) {
            throw new Error( "Needs ID to save" );
        }
        if ( data.events.length < 1 ) {
            throw new Error( "No changes to update" );
        }

        if ( this.URL ) {
            this.logger.info( "Creating new application" );
            //TODO: this.URL not working
            return this.apiUtils.put( this.URL + "/application/" + id, {}, data );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve( "" );
        }
    }

    async changeStatus( id: string, newStatus:ApplicationStatus ): Promise<void> {
        Guard.value( id ).against.beingEmptyOrWhitespace();
        Guard.value( newStatus ).against.beingEmptyOrWhitespace();

        if ( this.URL ) {
            this.logger.info( `Updating application status ${id} => ${newStatus}` );
            return this.apiUtils.put( this.URL + "/application/" + id + "/" + newStatus, {}, undefined );
        } else {
            this.logger.error( "Unable to send request to server" );
            return Promise.resolve();
        }
    }

}